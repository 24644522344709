import { logError } from 'fergy-core-react-logging';
import { type FocusEventHandler, type MouseEventHandler, forwardRef } from 'react';
import { AnalyticsHelper } from '../../../helpers/analytics/analytics.helper';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { type BaseButtonProps } from '../base-button/base-button.component';

export type ClickableElementProps = {
	ariaLabel: string;
	className?: string;
	testId?: string;
	id?: string;
	onClick?: MouseEventHandler<HTMLElement>;
	onBlur?: FocusEventHandler<HTMLDivElement>;
	onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
	onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
	onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
	tabIndex?: 0 | -1;
	isFullyOpaque?: boolean;
} & Omit<BaseButtonProps, 'className' | 'size' | 'onClick' | 'buttonType'>;

export const ClickableElement = forwardRef<HTMLDivElement, React.PropsWithChildren<ClickableElementProps>>(
	(
		{
			ariaLabel,
			automationHook,
			analyticsHook,
			children,
			className = '',
			id,
			onClick,
			onKeyDown,
			onBlur,
			onMouseEnter,
			onMouseLeave,
			tabIndex = 0,
			testId,
			analyticsEvent,
			disabled,
			isFullyOpaque = false,
			title
		},
		ref
	) => {
		const trackHandler = (
			eventHandler: React.EventHandler<React.SyntheticEvent<HTMLElement>> | undefined,
			evt: React.SyntheticEvent<HTMLElement>
		) => {
			if (!disabled) {
				if (analyticsEvent) {
					AnalyticsHelper.track(analyticsEvent.data)
						.catch(logError)
						.finally(() => {
							if (eventHandler) {
								eventHandler(evt);
							}
						});
				} else {
					if (eventHandler) {
						eventHandler(evt);
					}
				}
			}
		};
		const clickHandler = (evt: React.MouseEvent<HTMLDivElement>) => trackHandler(onClick, evt);
		const keyHandler = (evt: React.KeyboardEvent<HTMLDivElement>) => trackHandler(onKeyDown, evt);
		const disabledClass = isFullyOpaque ? 'not-allowed o-100' : 'not-allowed o-50';

		return (
			<div
				role="button"
				aria-label={ariaLabel}
				aria-disabled={disabled}
				className={`${disabled ? disabledClass : 'pointer'} ${className}`}
				onClick={clickHandler}
				onKeyDown={keyHandler}
				onBlur={onBlur}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				title={title}
				id={id}
				tabIndex={tabIndex}
				data-testid={testId}
				data-automation={generateDataSelector('button', automationHook)}
				data-analytics={generateDataSelector('button', analyticsHook)}
				ref={ref}>
				{children}
			</div>
		);
	}
);
