import { ACCEPTABLE_DELAY, DOHERTY_THRESHOLD } from '../../constants/general';

/**
 * check if window is defined.  Good for gating code that only executes on the client side
 */
export function doesWindowExist() {
	return typeof window !== 'undefined';
}

export function scrollToElement(id: string, headerOffset = 30) {
	const element = document.getElementById(id);

	if (!element) {
		return;
	}

	const elementPosition = element.getBoundingClientRect().top;
	// Determine offset position by adding element position with pageYOffset and subtracting the fixed header offset
	const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
	const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

	// Check is "behavior: smooth" is supported by the browser
	if (supportsNativeSmoothScroll) {
		window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
	} else {
		window.scrollTo(0, offsetPosition);
	}
}

/**
 * Scrolls to a given element inside a container by id.
 */
export function scrollContainerToElement(elementId: string, containerId = '', headerOffset = 30) {
	const element = document.getElementById(elementId);
	if (element && containerId) {
		const container = document.getElementById(containerId);
		if (container) {
			container.scrollTop = element.getBoundingClientRect().top + container.scrollTop - headerOffset;
		}
	}
}

/**
 * For debounce we do not want to exceed the Doherty threshold but we don't want
 * the debounce to be too short or it will not prevent needless calls as
 * intended. Ideally we want the doherty threshold - average response time of
 * the query in milliseconds.
 *
 * @see https://buildcom.github.io/styleguide/?path=/story/docs-best-practices--asynchronous-loading
 * @param averageResponseTime this is a tuning parameter that can be based on empirical evidence from apollo engine and/or rigor
 * @returns a number between 100 - 400 representing the debounce value in milliseconds
 */
export const inputDebounceTime = (averageResponseTime): number => {
	const time = DOHERTY_THRESHOLD - averageResponseTime;
	// return the calculated time if it is within the acceptable range
	if (time > ACCEPTABLE_DELAY && time < DOHERTY_THRESHOLD) {
		return time;
	}
	// return the floor (lower bound)
	if (time < ACCEPTABLE_DELAY) {
		return ACCEPTABLE_DELAY;
	}
	// return the ceiling (upper bound)
	return DOHERTY_THRESHOLD;
};

/**
 * Generate a window listener
 */
export function generateListener(setter: React.Dispatch<React.SetStateAction<boolean>>): EventListener {
	/**
	 * Listen for media query changes
	 */
	return function listen(ev: Event) {
		if (ev instanceof MediaQueryListEvent) {
			setter(ev.matches);
		}
	};
}

/**
 * Typeguard to differentiate between an Element and an HTMLElement.
 */
export function isHTMLElement(element: Element | null): element is HTMLElement {
	return element instanceof HTMLElement;
}
