import { type FunctionComponent, useContext } from 'react';
import { OVERLAY_MARKER } from '../../../../constants/general';
import { HOMEPAGE_LINK } from '../../../../constants/links';
import { useRouteChange } from '../../../../hooks/routing/route-change.hook';
import { IconWithLabelButton } from '../../../buttons/icon-with-label-button/icon-with-label-button.component';
import { Link } from '../../../common-components/link/link.component';
import { ProjectHeaderDropdown } from '../../../project-components/project-header-dropdown/project-header-dropdown.component';
import { OmniHomeSvg } from '../../../svg/general.component';
import { MenuIcon } from '../../../svg/icons.component';
import { CallCenterLink } from '../../call-center-link/call-center-link.component';
import { CartLink } from '../../cart-link/cart-link.component';
import { MobileAccountLink } from '../../mobile-account-link/mobile-account-link.component';
import { MobileNavContext, type MobileNavProps, MobileNavProvider, NavigationUI } from '../../mobile-nav/mobile-nav.component';
import { SearchBar } from '../../search-bar/search-bar.component';
import { navMenuLayout, divider, logoDimensions } from './omni-home-mobile-nav.css';

// TODO: EFDC-16856: reduce use of custom css for header
export const OmniHomeMobileNavComponent: FunctionComponent<MobileNavProps> = ({ startTabName }) => {
	const { isNavigationOpen, setIsNavigationOpen, setIsTypeaheadOpen } = useContext(MobileNavContext);
	const closeNavigation = () => setIsNavigationOpen(false);

	useRouteChange(() => {
		// make sure menu is closed if the user navigates to another page
		closeNavigation();
	});

	const showNavigation = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
		event.preventDefault();
		event.currentTarget.blur();
		setIsNavigationOpen(true);
	};

	return (
		<>
			{isNavigationOpen && <NavigationUI startTabName={startTabName} closeNavigation={closeNavigation} />}
			<div className="flex flex-column items-center dn-ns">
				{/* Logo */}
				<Link url={HOMEPAGE_LINK} className={`flex justify-center dn-ns w-100 tc pv2`} ariaLabel="Navigate to home page">
					<OmniHomeSvg className={`w-100 ${logoDimensions}`} />
				</Link>
				{/* Search Bar */}
				<div className="w-100 ph3">
					<SearchBar onTypeaheadOpenChanged={setIsTypeaheadOpen} isBuildDomain={false} placeholder="Search" />
				</div>
				{/* Nav Pane */}
				<div className={`${navMenuLayout} items-center w-100`}>
					{/* Menu */}
					<IconWithLabelButton
						onClick={showNavigation}
						label="Menu"
						icon={<MenuIcon />}
						buttonClass="pv2 w-100"
						ariaLabel="Site Navigation"
						theme="primary"
					/>
					{/* Account */}
					<MobileAccountLink theme="primary" />
					{/* Projects */}
					<ProjectHeaderDropdown />
					{/* Call */}
					<CallCenterLink theme="primary" containerClass="flex-row-ns" />
					{/* Cart */}
					<CartLink isBuild={false} isMobile={true} />
				</div>
				{/* Divider */}
				<div className={`${OVERLAY_MARKER} db dn-ns`} />
				<div className={`w-100 mb1 ${divider}`} />
			</div>
		</>
	);
};

export const OmniHomeMobileNav: FunctionComponent<MobileNavProps> = (props) => (
	<MobileNavProvider isNavigationOpen={props.startOpen || false}>
		<OmniHomeMobileNavComponent {...props} />
	</MobileNavProvider>
);
