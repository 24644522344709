import loadable from '@loadable/component';
import { logError } from 'fergy-core-react-logging';
import { type Location } from 'history';
import { type FunctionComponent, useEffect, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SEARCH_ROUTE } from '../../../constants/links';
import { REGEX_WHITE_SPACE } from '../../../constants/regular-expression';
import { MAX_QUERY_LENGTH } from '../../../constants/search-bar';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { preloadComponentForUrl } from '../../../helpers/routing/route.helpers';
import { useControlledFocus } from '../../../hooks/general/controlled-focus.hooks';
import { useNavigation } from '../../../hooks/navigation/navigation.hooks';
import type { TypeaheadAction, TypeaheadState } from '../../../reducers/search-bar/search-bar-reducer.types';
import { ClickableElement } from '../../buttons/clickable-element/clickable-element.component';
import { IconButton } from '../../buttons/icon-button/icon-button.component';
import { Overlay } from '../../common-components/overlay/overlay.component';
import { TextInput } from '../../inputs';
import { InputGroup } from '../../inputs/input-group/input-group.component';
import { SearchIcon } from '../../svg/icons.component';
import { cancelButton, hide, searchContainer, wide } from './search-bar.css';

const LoadableSearchBarResults = loadable(
	() => import(/* webpackChunkName: "search-bar-results" */ '../search-bar-results/search-bar-results.component'),
	{ resolveComponent: ({ SearchBarResults }) => SearchBarResults }
);

// We will dynamically load the search bar controller module when needed.
type SearchBarControllerModule = typeof import('./search-bar-controller');
let SearchBarController: SearchBarControllerModule | undefined;

async function loadSearchBarController(): Promise<void> {
	if (!SearchBarController) {
		SearchBarController = await import(/* webpackChunkName: "search-bar-controller" */ './search-bar-controller');
	}
}

function preloadTypeaheadModules() {
	void loadSearchBarController();
	LoadableSearchBarResults.preload();
}

let searchPagePreloaded = false;

function preloadSearchPage() {
	if (!searchPagePreloaded) {
		preloadComponentForUrl(SEARCH_ROUTE);
		searchPagePreloaded = true;
	}
}

const DEFAULT_TYPEAHEAD_STATE: TypeaheadState = {
	value: '',
	displayValue: '',
	currentSearch: '',
	suggestionsLoading: false,
	valueChanged: false,
	inputFocused: false,
	showResults: false,
	showRecent: false,
	showSuggestions: false,
	cursorSection: 'input',
	recentTermsCursorIndex: -1,
	recentTermsRemoveCursorIndex: -1,
	suggestedTermsCursorIndex: -1,
	suggestedProductsCursorIndex: -1,
	recentTerms: [],
	recentTermsRemove: [],
	suggestedTerms: [],
	suggestedProducts: [],
	suggestedProductsColumns: 3,
	renderProducts: false
};

function initTypeaheadState(stateInit: { inputValue: string }): TypeaheadState {
	const { inputValue } = stateInit;
	return { ...DEFAULT_TYPEAHEAD_STATE, value: inputValue.trim(), displayValue: inputValue, currentSearch: inputValue.trim() };
}

/**
 * Stub for typeahead reducer to ensure basic input works while controller is loading.
 */
function reducerStub(state: TypeaheadState, action: TypeaheadAction): TypeaheadState {
	if (action.type === 'inputChanged') {
		return { ...state, value: action.value.trim(), displayValue: action.value, valueChanged: true };
	}
	return state;
}

function getUrlSearchTerm(location: Location): string {
	return new URLSearchParams(location.search).get('term') ?? '';
}

export type SearchBarProps = {
	onTypeaheadOpenChanged?: (isOpen: boolean) => void;
	isBuildDomain?: boolean;
	placeholder?: string;
};

/**
 * Renders a search input that fetches recent searches and typeahead suggested products and terms and displays them in a popup below.
 */
// TODO: EFDC-16856: reduce use of custom css for header
export const SearchBar: FunctionComponent<SearchBarProps> = ({
	onTypeaheadOpenChanged,
	isBuildDomain = true,
	placeholder = 'What are you shopping for?'
}) => {
	const [typeaheadLoaded, setTypeaheadLoaded] = useState(Boolean(SearchBarController));
	const navigate = useNavigation();
	const history = useHistory();
	const urlTerm = getUrlSearchTerm(history.location);
	const [state, dispatch] = useReducer(SearchBarController?.searchBarReducer ?? reducerStub, { inputValue: urlTerm }, initTypeaheadState);

	const inputRef = useRef<HTMLInputElement>(null);
	useControlledFocus(inputRef, state.inputFocused);

	// Reset typeahead on navigation
	useEffect(() => history?.listen((location) => dispatch({ type: 'locationChanged', term: getUrlSearchTerm(location) })), [history]);

	const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
		event.preventDefault();
		const searchTerm = state.displayValue.replace(REGEX_WHITE_SPACE, ' ').trim();
		if (!searchTerm) {
			return;
		}
		dispatch({ type: 'typeaheadClosed' });
		navigate(`/search?term=${encodeURIComponent(searchTerm)}`);
	};
	const handleInputFocus = async () => {
		if (!typeaheadLoaded) {
			try {
				await loadSearchBarController();
				setTypeaheadLoaded(true);
			} catch (error) {
				logError(error);
			}
		}
		if (!state.inputFocused) {
			dispatch({ type: 'inputFocused' });
		}
	};
	const handleInputBlur = () => state.inputFocused && dispatch({ type: 'inputBlurred' });
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch({ type: 'inputChanged', value: event.target.value });
		preloadSearchPage();
	};
	const handleKeyDown = (event: React.KeyboardEvent) => SearchBarController?.handleKeyDown(state, dispatch, event);
	const handleCloseTypeahead = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
		event.preventDefault();
		event.currentTarget.blur();
		dispatch({ type: 'typeaheadClosed' });
	};

	useEffect(() => {
		onTypeaheadOpenChanged?.(state.showResults);
	}, [onTypeaheadOpenChanged, state.showResults]);

	const wideClass = state.inputFocused || state.showResults ? `${wide} absolute-ns omniHomeSearchBarOpen` : '';
	const zIndex = state.showResults ? 'z-3' : 'z-1'; // Raise z-index when results are showing so it is above the nav bar.
	const formZIndex = state.showResults ? 'z-4' : ''; // Use higher z-index than the overlay so search input clicks aren't blocked.

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<div
			role="search"
			className={`fl-ns w-100 ${
				isBuildDomain ? 'absolute-ns mt1-ns w-40-ns' : ''
			} omniHomeSearchBar ${zIndex} ${searchContainer} ${wideClass} omniSearchBarHeight omniSearchBarNoMaxWidth`}
			onKeyDown={handleKeyDown}>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions*/}
			<form
				action={SEARCH_ROUTE}
				onSubmit={handleFormSubmit}
				className={`relative flex ${formZIndex}`}
				aria-label="site wide search form"
				onMouseEnter={preloadTypeaheadModules}>
				<InputGroup className="flex-auto">
					<TextInput
						name="term"
						ariaLabel="Search"
						automationHook="search"
						value={state.displayValue}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						onChange={handleInputChange}
						placeholder={placeholder}
						data-testid="SearchInput"
						autoComplete="off"
						autoCapitalize="off"
						autoCorrect="off"
						maxLength={MAX_QUERY_LENGTH}
						className="f5 f6-m omniSearchBarHeight"
						required={true}
						ref={inputRef}
					/>
					<IconButton size="ICON" buttonType="submit" buttonStyle="ICON" ariaLabel="Submit search">
						<SearchIcon className="db f4" />
					</IconButton>
				</InputGroup>
				<ClickableElement
					ariaLabel="Close search suggestions"
					onClick={handleCloseTypeahead}
					onKeyDown={handleKeys([' ', 'Enter'], handleCloseTypeahead)}
					className={`${cancelButton} ${
						state.showResults ? '' : hide
					} flex items-center dn-ns input nowrap ph2 ml1 f5 lh-title theme-white ba bw1 br2 b--transparent hover-b--theme-grey-lighter active-bg-theme-grey-darker omniHomeMobileCancel omniSearchBarHeight`}>
					Cancel
				</ClickableElement>
			</form>
			{state.showResults && (
				<>
					<Overlay style="dark" hasTransparentHeader={true} onClick={handleCloseTypeahead} />
					<LoadableSearchBarResults state={state} dispatch={dispatch} />
				</>
			)}
		</div>
	);
};
